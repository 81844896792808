const phoneInputs = document.querySelectorAll('.phone');

phoneInputs.forEach(input => {
    input.addEventListener('input', function (e) {
        let inputValue = e.target.value.replace(/\D/g, '');

        if (inputValue.length > 10) {
            inputValue = inputValue.slice(0, 10);
        }

        if (inputValue.length === 0) {
            e.target.value = '';
        } else {
            const formattedValue = '(' + inputValue.substring(0, 3) + ') ' + inputValue.substring(3, 6) + '-' + inputValue.substring(6, 10);
            e.target.value = formattedValue;
        }
    });
});

//logo slider
$('.logo-slider').slick({
    speed: 300,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    centerPadding: '80px',
    arrows: false,
    slidesToShow: 7,

    responsive: [
        {
            breakpoint: 1024,
            settings: {

                slidesToShow: 4,
                autoplay: true,
            }
        },
        {
            breakpoint: 768,
            settings: {

                slidesToShow: 3,
                autoplay: true,
            }
        },
        {
            breakpoint: 480,
            settings: {

                slidesToShow: 2,
                autoplay: true,
            }
        }
    ]
});